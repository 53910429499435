import React from "react";
import SubHeaderComponent from "../components/SubHeaderComponent";

export default function DespreNoi() {
  const styles = {
    container: {
      maxWidth: "1200px",
      margin: "40px auto",
      background: "#fff",
      padding: "30px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    headingPrimary: {
      color: "#0066cc",
      borderBottom: "2px solid #0066cc",
      paddingBottom: "10px",
      marginBottom: "20px",
    },
    headingSecondary: {
      color: "#0066cc",
      marginTop: "30px",
      marginBottom: "10px",
    },
    paragraph: {
      marginBottom: "20px",
      fontSize: "16px",
    },
    list: {
      listStyleType: "none",
      padding: 0,
      fontSize: "16px",
    },
    listItem: {
      marginBottom: "10px",
      paddingLeft: "20px",
      textIndent: "-20px",
    },
    listItemIcon: {
      color: "#0066cc",
      marginRight: "10px",
    },
  };

  return (
    <div>
      <SubHeaderComponent text={"Despre noi"} />
      <div style={styles.body}>
        <div style={styles.container}>
          <p style={styles.paragraph}>
            Bine ați venit la WoodWolfBioProd, locul unde inovația întâlnește
            sustenabilitatea. Suntem o fabrică mică, dar ambițioasă,
            specializată în producția de peleti și brichete de înaltă calitate,
            dedicate atât consumatorilor casnici, cât și celor industriali.
          </p>

          <h2 style={styles.headingSecondary}>Povestea Noastră</h2>
          <p style={styles.paragraph}>
            Fondată în 2023, WoodWolfBioProd a luat naștere din dorința de a
            oferi soluții ecologice și eficiente pentru încălzire. Am început ca
            o mică afacere de familie, crescând treptat prin investiții în
            tehnologii moderne și prin dedicarea unei echipe pasionate de mediu
            și sustenabilitate.
          </p>

          <h2 style={styles.headingSecondary}>Misiunea Noastră</h2>
          <p style={styles.paragraph}>
            Misiunea noastră este simplă: să furnizăm peleti și brichete de
            calitate superioară, produse din materiale 100% naturale și
            regenerabile. Ne angajăm să contribuim activ la reducerea amprentei
            de carbon și să oferim clienților noștri alternative ecologice și
            eficiente energetic pentru încălzirea locuințelor și a spațiilor
            industriale.
          </p>

          <h2 style={styles.headingSecondary}>Valorile Noastre</h2>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <span style={styles.listItemIcon}>✓</span>
              <strong>Sustenabilitate:</strong> Utilizăm doar materii prime
              regenerabile și procese de producție ecologice, pentru a minimiza
              impactul asupra mediului.
            </li>
            <li style={styles.listItem}>
              <span style={styles.listItemIcon}>✓</span>
              <strong>Calitate:</strong> Asigurăm standarde înalte de calitate
              printr-un control riguros al procesului de producție și prin
              utilizarea celor mai bune echipamente.
            </li>
            <li style={styles.listItem}>
              <span style={styles.listItemIcon}>✓</span>
              <strong>Inovație:</strong> Ne adaptăm constant la noile tehnologii
              și cerințe ale pieței, pentru a oferi produse inovatoare și
              eficiente.
            </li>
            <li style={styles.listItem}>
              <span style={styles.listItemIcon}>✓</span>
              <strong>Transparență:</strong> Comunicăm deschis cu clienții
              noștri și oferim informații clare despre produsele și procesele
              noastre.
            </li>
          </ul>

          <h2 style={styles.headingSecondary}>Ce Ne Deosebește</h2>
          <p style={styles.paragraph}>
            La WoodWolfBioProd, fiecare produs este rezultatul unei munci
            meticuloase și al unei atenții deosebite la detalii. Folosim
            echipamente moderne și tehnologii avansate pentru a produce peleti
            și brichete cu performanțe excepționale. Toate produsele noastre
            sunt testate și certificate pentru a garanta siguranța și eficiența
            lor.
          </p>

          <h2 style={styles.headingSecondary}>Contactați-ne</h2>
          <p style={styles.paragraph}>
            Suntem aici pentru a răspunde tuturor întrebărilor dumneavoastră și
            pentru a vă ajuta să alegeți cea mai bună soluție de încălzire
            pentru nevoile dumneavoastră. Nu ezitați să ne contactați pentru mai
            multe informații sau pentru a plasa o comandă.
          </p>

          <p style={styles.paragraph}>
            Mulțumim că ați ales WoodWolfBioProd – partenerul dumneavoastră în
            soluții de încălzire ecologice și eficiente!
          </p>
        </div>
      </div>
    </div>
  );
}
