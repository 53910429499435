import React from "react";
import { Card } from "react-bootstrap";

const ProductComponent = (props) => {
  return (
    <Card style={{ width: "18rem", cursor: "pointer" }}>
      <Card.Img
        variant="top"
        src={props.getImage(props.prod.image)}
        width={150}
        height={200}
      />
      <Card.Body>
        <Card.Title>{props.prod.name}</Card.Title>
        <Card.Text>{props.prod.price}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductComponent;
