import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import { FormPage } from "./pages/FormPage";
import NavbarComponent from "./components/NavbarComponent";
import FooterComponent from "./components/FooterComponent";
import PeletiPage from "./pages/PeletiPage";
import BrichetePage from "./pages/BrichetePage";
import LemnDeFocPage from "./pages/LemnDeFoc";
import DespreNoi from "./pages/DespreNoi";

function App() {
  return (
    <div className="App">
      <Router>
        <NavbarComponent />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/peleti" element={<PeletiPage />} />
          <Route path="/brichete" element={<BrichetePage />} />
          <Route path="/lemn" element={<LemnDeFocPage />} />
          <Route path="/despre-noi" element={<DespreNoi />} />

          <Route path="/comanda" element={<FormPage />} />
        </Routes>
      </Router>
      <FooterComponent />
    </div>
  );
}

export default App;
