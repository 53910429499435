import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function FooterComponent() {
  return (
    <footer className="bg-dark text-white" style={{ minHeight: "170px" }}>
      <Container>
        <Row>
          <Col style={{ paddingTop: 15 }}>
            <h5>Adresa</h5>
            <p>
              Adresa: Sat Grozavesti, Comuna Corbii Mari, Str. Grozavesti, Nr.
              16, Judet Dambovita
            </p>
          </Col>
          <Col style={{ paddingTop: 15 }}>
            <h5>Contactati-ne</h5>
            <p>Email: woodwolfbioprod@gmail.com</p>
            <p>Phone: +(40) 0729 927 009</p>
          </Col>
          <Col style={{ paddingTop: 15 }}>
            <h5>Urmareste-ne</h5>
            <p>
              <a href="https://www.facebook.com/profile.php?id=61560658726796">
                Facebook
              </a>
            </p>
            <p>
              <a href="https://www.instagram.com/fabricadepeletisibrichete?igsh=YWh0OTl2MXRwYmtv">
                Instagram
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
