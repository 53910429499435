import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NavbarComponent = ({ setWebpage }) => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" style={{ minHeight: "80px" }}>
      <Container>
        <Navbar.Brand style={{ cursor: "pointer" }} as={Link} to="/">
          <img
            src={require("../assets/primary-logo.jpeg")}
            alt="Logo"
            width={35}
          />
          <span style={{ marginLeft: 10, fontSize: 22 }}>
            WOODWOLF BIO PROD
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/peleti">
              Peleti
            </Nav.Link>
            <Nav.Link as={Link} to="/brichete">
              Brichete Rumegus
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/lemn">
              Lemn de foc
            </Nav.Link> */}
            <Nav.Link as={Link} to="/despre-noi">
              Despre noi
            </Nav.Link>
            <Nav.Link as={Link} to="/comanda">
              Comanda
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
