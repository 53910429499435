// ./components/SawdustBriquettesInfo.js
import React from "react";
import "./SawdustBriquettesInfo.css";

const BricheteTextComponent = () => {
  return (
    <div className="briquettes-info">
      <h2>Brichete de Rumeguș Premium</h2>
      <h6>
        Îmbunătățește eficiența și confortul sistemului tău de încălzire cu
        brichetele de rumeguș premium. Proiectate pentru a oferi o ardere de
        lungă durată și curată, brichetele noastre sunt soluția perfectă pentru
        cei care caută un combustibil ecologic și economic.
      </h6>
      <h4>Caracteristici:</h4>
      <ul>
        <li>
          <strong>Compoziție 100% naturală:</strong> Brichetele sunt fabricate
          exclusiv din rumeguș de lemn comprimat, fără aditivi sau substanțe
          chimice, garantând o ardere curată și sigură.
        </li>
        <li>
          <strong>Putere calorică ridicată:</strong> Cu o densitate mare și un
          conținut scăzut de umiditate, brichetele oferă o putere calorică
          superioară, asigurând o încălzire eficientă și constantă.
        </li>
        <li>
          <strong>Ardere îndelungată:</strong> Designul compact și compoziția de
          calitate permit o ardere lentă și uniformă, reducând frecvența de
          reîncărcare a sobei sau cazanului.
        </li>
        <li>
          <strong>Conținut scăzut de cenușă:</strong> Arderea brichetelor de
          rumeguș produce o cantitate minimă de cenușă, facilitând curățarea și
          întreținerea echipamentului de încălzire.
        </li>
      </ul>
      <h4>Beneficii:</h4>
      <ul>
        <li>
          <strong>Eficiență economică:</strong> Brichetele de rumeguș sunt o
          alternativă rentabilă la lemnul tradițional și la alți combustibili,
          oferind un raport excelent între cost și performanță.
        </li>
        <li>
          <strong>Ecologice și sustenabile:</strong> Produse din resturi de lemn
          regenerabile, brichetele contribuie la reducerea defrișărilor și la
          protejarea mediului înconjurător.
        </li>
        <li>
          <strong>Compatibilitate:</strong> Brichetele sunt compatibile cu o
          gamă largă de sisteme de încălzire, inclusiv sobe, cazane, șeminee și
          centrale termice pe lemne.
        </li>
        <li>
          <strong>Depozitare și manipulare ușoară:</strong> Disponibile în
          pachete compacte, brichetele sunt ușor de depozitat și manipulat,
          economisind spațiu și timp.
        </li>
      </ul>
      <h4>Utilizare:</h4>
      <p>
        Pentru rezultate optime, utilizează brichetele de rumeguș în
        echipamentele de încălzire conforme cu specificațiile producătorului.
        Plasează brichetele în compartimentul de ardere și bucură-te de o
        căldură constantă și confortabilă.
      </p>
      <h4>Comandă acum!</h4>
      <p>
        Alege brichetele de rumeguș premium pentru o soluție de încălzire
        eficientă și prietenoasă cu mediul. Vizitează secțiunea noastră de
        produse și selectează cantitatea potrivită pentru nevoile tale.
        Beneficiază de livrare rapidă și de suportul echipei noastre pentru
        orice întrebări sau solicitări speciale.
      </p>
    </div>
  );
};

export default BricheteTextComponent;
