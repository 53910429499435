// ./components/PelletInfo.js
import React from "react";
import "./PeletiTextComponent.css";

const PeletiTextComponent = () => {
  return (
    <div className="pellet-info">
      <h2>Peleti de Foc Premium</h2>
      <h6>
        Descoperă soluția ideală pentru încălzirea eficientă și ecologică a
        casei tale cu peletii de foc premium. Produși din lemn de esență tare și
        alte materiale naturale, peletii noștri sunt proiectați pentru a oferi o
        ardere constantă și economică, fiind potriviți pentru diverse tipuri de
        sisteme de încălzire.
      </h6>
      <h4>Caracteristici:</h4>
      <ul>
        <li>
          <strong>Calitate superioară:</strong> Peletii sunt fabricați din
          rumeguș și așchii de lemn comprimate, fără aditivi sau substanțe
          chimice, asigurând o ardere curată și ecologică.
        </li>
        <li>
          <strong>Putere calorică ridicată:</strong> Peletii noștri oferă o
          putere calorică excelentă, asigurând o eficiență termică superioară
          pentru încălzirea locuinței tale.
        </li>
        <li>
          <strong>Conținut redus de cenușă:</strong> Datorită procesului de
          fabricație avansat, peletii generează o cantitate minimă de cenușă,
          facilitând curățarea și întreținerea sobelor și cazanelor.
        </li>
        <li>
          <strong>Ambalare convenabilă:</strong> Disponibili în saci de 15 kg,
          peletii sunt ușor de manipulat și de depozitat, oferind flexibilitate
          și comoditate în utilizare.
        </li>
      </ul>
      <h4>Beneficii:</h4>
      <ul>
        <li>
          <strong>Eficiență economică:</strong> Peletii de foc sunt o
          alternativă economică la alte tipuri de combustibili, oferind un
          raport excelent între preț și performanță.
        </li>
        <li>
          <strong>Sustenabilitate:</strong> Produși din resturi de lemn și alte
          materiale naturale, peletii sunt o opțiune ecologică, contribuind la
          reducerea emisiilor de carbon și la protejarea mediului înconjurător.
        </li>
        <li>
          <strong>Compatibilitate:</strong> Peletii sunt compatibili cu
          majoritatea tipurilor de sobe și cazane automate pe peleți, oferind
          flexibilitate în alegerea sistemului de încălzire.
        </li>
        <li>
          <strong>Conveniență:</strong> Arderea peletilor nu necesită
          reîncărcări frecvente, asigurând o funcționare continuă și fără bătăi
          de cap.
        </li>
      </ul>
      <h4>Utilizare:</h4>
      <p>
        Pentru o performanță optimă, asigură-te că soba sau cazanul tău este
        potrivit pentru arderea peletilor. Urmează instrucțiunile de utilizare
        ale producătorului echipamentului tău și bucură-te de căldura constantă
        și confortabilă oferită de peletii de foc premium.
      </p>
    </div>
  );
};

export default PeletiTextComponent;
