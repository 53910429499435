import React, { useState } from "react";
import SubHeaderComponent from "../components/SubHeaderComponent";
import ProductComponent from "../components/ProductComponent";
import "./peleti-page.css";
import PeletiTextComponent from "../components/PeletiTextComponent";

export default function PeletiPage() {
  const getImage = (image) => {
    switch (image) {
      case 1:
        return require("../assets/peleti-15kg.jpeg");
      case 2:
        return require("../assets/peleti-1tona.jpeg");
      default:
        return;
    }
  };

  const prod1 = {
    name: "Peleti Rumegus 15kg",
    price: "15 RON",
    image: 1,
  };
  const prod2 = {
    name: "Peleti Rumegus 1 Tona",
    price: "1000 RON",
    image: 2,
  };
  const prods = [prod1, prod2];

  const [products, setPeleti] = useState(prods);

  return (
    <div>
      <SubHeaderComponent text={"Peleti de foc premium"} />

      <div className="product-container">
        {products.map((prod) => {
          return <ProductComponent prod={prod} getImage={getImage} />;
        })}
      </div>
      <PeletiTextComponent />
    </div>
  );
}
