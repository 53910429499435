import React from "react";
import ProductComponent from "./ProductComponent";
import "./ProductListComponent.css";

export default function ProductListComponent() {
  const getImage = (image) => {
    switch (image) {
      case 1:
        return require("../assets/product-3.png");
      case 2:
        return require("../assets/brichete.jpeg");
      case 3:
        return require("../assets/product-1.png");
      default:
        return require("../assets/product-1.png");
    }
  };
  const prod1 = {
    name: "Peleti rumegus",
    price: "1000RON/tona",
    image: 1,
  };
  const prod2 = {
    name: "Brichete rumegus",
    price: "1200RON/tona",
    image: 2,
  };

  const products = [prod1, prod2];
  return (
    <div className="product-container">
      {products.map((prod) => (
        <ProductComponent prod={prod} getImage={getImage} />
      ))}
    </div>
  );
}
