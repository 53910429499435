// ./components/FirewoodInfo.js
import React from "react";
import "./LemnDeFocTextComponent.css";

const LemnDeFocTextComponent = () => {
  return (
    <div className="firewood-info">
      <h2>Lemn de Foc Premium</h2>
      <p>
        Transformă serile răcoroase într-o experiență caldă și confortabilă cu
        lemnul de foc premium, ideal pentru șemineu, sobă sau focul de tabără.
        Lemnul nostru de foc este selectat cu grijă pentru a asigura o ardere de
        lungă durată și eficientă, oferindu-ți o căldură constantă și plăcută.
      </p>
      <h4>Caracteristici:</h4>
      <ul>
        <li>
          <strong>Specii de lemn de înaltă calitate:</strong> Folosim doar lemn
          de esență tare, precum stejarul, fagul sau carpenul, recunoscute
          pentru capacitatea lor de ardere și puterea calorică superioară.
        </li>
        <li>
          <strong>Uscat natural:</strong> Lemnul este uscat în mod natural timp
          de minimum 12 luni, garantând un conținut scăzut de umiditate pentru o
          ardere eficientă și minimizarea fumului.
        </li>
        <li>
          <strong>Dimensiuni ideale:</strong> Bucățile de lemn sunt tăiate la
          dimensiuni standard, adaptate pentru utilizarea în diferite tipuri de
          sobe și șeminee.
        </li>
        <li>
          <strong>Ecologic:</strong> Provenit din surse sustenabile, lemnul
          nostru de foc respectă normele de mediu și contribuie la protejarea
          pădurilor.
        </li>
      </ul>
      <h4>Beneficii:</h4>
      <ul>
        <li>
          <strong>Eficiență termică ridicată:</strong> Lemnul de foc premium
          asigură o temperatură constantă și plăcută în încăperi, reducând
          nevoia de reaprovizionare frecventă.
        </li>
        <li>
          <strong>Ardere curată:</strong> Datorită conținutului scăzut de
          umiditate și esenței lemnului, arde cu mai puțin fum și scântei, fiind
          ideal pentru utilizare în interior.
        </li>
        <li>
          <strong>Versatilitate:</strong> Potrivit pentru diverse sisteme de
          încălzire - șeminee deschise sau închise, sobe de teracotă, sobe de
          lemn și chiar pentru grătare și focuri de tabără.
        </li>
        <li>
          <strong>Ambalare convenabilă:</strong> Disponibil în pachete de
          diferite dimensiuni, lemnul de foc premium poate fi achiziționat în
          funcție de necesitățile tale, de la saci de 20 kg până la paleți de 1
          tonă.
        </li>
      </ul>
      <h4>Utilizare:</h4>
      <p>
        Pentru a obține cele mai bune rezultate, asigură-te că ventilarea
        camerei este adecvată și respectă instrucțiunile producătorului
        șemineului sau sobei tale. Aprinde lemnul cu ajutorul unor surse de foc
        sigure și bucură-te de o atmosferă caldă și primitoare.
      </p>
      <h4>Comandă acum!</h4>
      <p>
        Nu lăsa frigul să-ți strice confortul. Alege lemnul de foc premium
        pentru o căldură naturală și eficientă. Vizitează secțiunea noastră de
        produse și alege pachetul care ți se potrivește cel mai bine.
        Beneficiază de livrare rapidă și de suportul echipei noastre pentru
        orice întrebări sau nevoi speciale.
      </p>
      <h4>Contact</h4>
      <p>
        Pentru mai multe detalii sau comenzi speciale, nu ezita să ne
        contactezi. Echipa noastră de specialiști îți stă la dispoziție pentru
        a-ți oferi cele mai bune soluții de încălzire.
      </p>
    </div>
  );
};

export default LemnDeFocTextComponent;
