import React, { useState } from "react";
import SubHeaderComponent from "../components/SubHeaderComponent";
import ProductComponent from "../components/ProductComponent";
import "./peleti-page.css";
import BricheteTextComponent from "../components/BricheteTextComponent";
// import BricheteTextComponent from "../components/BricheteTextComponent";

export default function BrichetePage() {
  const getImage = (image) => {
    switch (image) {
      case 1:
        return require("../assets/brichete.jpeg");
      case 2:
        return require("../assets/brichete-tona-update.jpeg");
      default:
        return;
    }
  };

  const prod1 = {
    name: "Brichete 15kg",
    price: "18 RON",
    image: 1,
  };
  const prod2 = {
    name: "Brichete 1 Tona",
    price: "1200 RON",
    image: 2,
  };
  const prods = [prod1, prod2];

  const [products] = useState(prods);

  return (
    <div>
      <SubHeaderComponent text={"Brichete de foc premium"} />

      <div className="product-container">
        {products.map((prod) => {
          return <ProductComponent prod={prod} getImage={getImage} />;
        })}
      </div>
      <BricheteTextComponent />
    </div>
  );
}
