import SubHeaderComponent from "../components/SubHeaderComponent";
import ProductListComponent from "../components/ProductListComponent";

export default function LandingPage() {
  return (
    <div>
      <div>
        <SubHeaderComponent text={"Solutii inteligente de incalzire"} />
        <ProductListComponent />
      </div>
    </div>
  );
}
