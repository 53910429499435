import React from "react";
import "./SubHeaderComponent.css";

export default function SubHeaderComponent({ text }) {
  return (
    <div className="header-container">
      <h2>{text}</h2>
    </div>
  );
}
