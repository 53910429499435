import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import "./FormPage.css";
import emailjs from "emailjs-com";

export const FormPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name && !formData.phone && !formData.message) {
      alert("Va rugam introduceti toate datele pentru comanda");
      return;
    }

    const templateParams = {
      to_email: "woodwolfbioprod@gmail.com",
      subject: `Comanda ${formData.name + " " + formData.phone} `,
      message: formData.message,
    };

    emailjs
      .send(
        "service_2l6qpq7",
        "template_pqpsqde",
        templateParams,
        "euham5C6q4LCt3vyL"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setFormData({
            name: "",
            phone: "",
            message: "",
          });
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  //   emailjs
  //     .sendForm("service_2l6qpq7", "template_pqpsqde", templateParams, {
  //       publicKey: "euham5C6q4LCt3vyL",
  //     })
  //     .then(
  //       (response) => {
  //         console.log("SUCCESS!", response.status, response.text);
  //         alert("Email sent successfully!");
  //       },
  //       (error) => {
  //         console.error("FAILED...", error);
  //         alert("Failed to send email.");
  //       }
  //     );
  // };

  return (
    <>
      <Container
        style={{ maxWidth: "50%", marginBottom: 50 }}
        className="form-container"
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Nume</Form.Label>
            <Form.Control
              type="text"
              placeholder="Scrieti-va numele"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formPhone" style={{ marginTop: 30 }}>
            <Form.Label>Telefon</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Introduceti numarul de telefon"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formMessage" style={{ marginTop: 30 }}>
            <Form.Label>Mesaj</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Mesajul dumneavoastra"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            style={{ marginTop: 30, width: "100%" }}
          >
            Submit
          </Button>
        </Form>
      </Container>
    </>
  );
};
